const myLib = {
    // 获取地址栏参数（#前）
    getQuery(name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        let str = window.location.href.split('?')[1];
        if (!str) {
            return null;
        }
        let r = str.match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        return null;
    },
    deepLink() {
        // get userAgent
        var u = window.navigator.userAgent;
        var requestAnimationF = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        var startTime = null; //起始时间
        var openTimer = null; //唤起定时器
        // define object
        var tools = {
            isAndroid: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            isIOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            isWeibo: u.toLowerCase().match(/weibo/i) == "weibo",
            isWxOrQQ: function () {
                if (tools.isIOS) {
                    return (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger" || u.toLowerCase().match(/QQ/i) == "qq");
                } else {
                    return (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger");
                }
            },
            getAndroidVersion: function () {

                return u.substr(u.indexOf('Android') + 8, 3)
            },
            getIOSVersion: function () {
                var reg = /OS ((\d+_?){2,3})\s/;
                if (navigator.userAgent.match(/iPad/i) || navigator.platform.match(/iPad/i) || navigator.userAgent.match(/iP(hone|od)/i) || navigator.platform.match(/iP(hone|od)/i)) {
                    var osv = reg.exec(navigator.userAgent);
                    if (osv.length > 0) {
                        // return osv[0].replace('OS', '').replace('os', '').replace(/\s+/g, '').replace(/_/g, '.')
                        return parseInt(osv[0].replace('OS', '').replace('os', '').replace(/\s+/g, '').replace(/_/g, '.').split('.')[0]);

                    }
                }
                return ''
            },
            openInWxOrQQ: function (defaulePara) {
                var uri, ecduri;
                var iOSversion = tools.getIOSVersion();
                if (tools.isIOS) {
                    uri = defaulePara['uri'].ios;

                    if (iOSversion >= 9) {
                        ecduri = encodeURI(defaulePara['uri'].ios);
                        window.location.href = defaulePara['prefix'] + ecduri;
                        // return;
                    }

                } else {
                    window.location.href = defaulePara['downLink'];
                }
                tools.download(defaulePara);
                return;
            },
            download: function (defaulePara) {
                function openLink() {
                    var iOSversion = tools.getIOSVersion();
                    var timestamp = Date.now(); //执行时间
                    var diff = timestamp - startTime; //执行间隔
                    // if (iOSversion >= 9) return;
                    if (diff > 2000 && diff < 3500) {
                        //执行语句
                        if (!defaulePara['isInside']) {
                            window.location.href = defaulePara['downLink'] || 'https://m.litup.cc/';
                        }
                    } else if (diff < 3500) {
                        //递归循环
                        openTimer = requestAnimationF(openLink);
                    } else {
                        return;
                    }
                }
                if (requestAnimationF) {
                    startTime = Date.now(); //起始时间
                    openTimer = requestAnimationF(openLink);
                } else {
                    setTimeout(function () {
                        if (!defaulePara['isInside']) {
                            window.location.href = defaulePara['downLink'];
                        }
                    }, 2000);
                }

            },
            isEmptyObject: function (obj) {
                for (var key in obj) {
                    return false;
                }
                return true;
            }
        }

        return {
            open: function (para) {
                var ecduri;
                var defaulePara = { ...para };
                defaulePara['uri'] = defaulePara['uri'] || {}
                const { type, params } = para
                if (type === 'userPage') {
                    // uid, videoId, videoUrl
                    defaulePara['uri'].ios = `litup://router/userPage?uid=${params.uid}`;
                    defaulePara['uri'].android = `litup://router/userPage?uid=${params.uid}`;
                }
                if (type === 'videoPlay') {
                    // uid, videoId, videoUrl
                    defaulePara['uri'].ios = `litup://router/videoPlay?videoId=${params.video_id}&videoUrl=${params.video_url}`;
                    defaulePara['uri'].android = `litup://router/videoPlay?videoId=${params.video_id}&videoUrl=${params.video_url}`;
                }
                if (type === 'livePage') {
                    // uid, videoId, videoUrl
                    defaulePara['uri'].ios = `litup://router/voice_room?id=${params.room_id}`;
                    defaulePara['uri'].android = `litup://router/voice_room?id=${params.room_id}`;
                }
                defaulePara['isInside'] = para['isInside'] || false;
                defaulePara['prefix'] = ''
                if (tools.isEmptyObject(defaulePara['uri'])) {
                    return;
                }

                if ((defaulePara['uri'] !== null) || '') {
                    if (tools.isWxOrQQ()) {
                        tools.openInWxOrQQ(defaulePara);
                        return;
                    }
                    if (tools.isIOS) {
                        ecduri = encodeURI(defaulePara['uri'].ios);
                        if (defaulePara['isInside']) {

                            window.location.href = ecduri;
                            return;
                        }

                        if (tools.getIOSVersion() >= 9) {
                            window.location.href = defaulePara['prefix'] + ecduri;
                        } else {
                            window.location.href = ecduri;
                        }

                        tools.download(defaulePara);

                        return;
                    } else if (tools.isAndroid) {
                        ecduri = encodeURI(defaulePara['uri'].android);

                        if (defaulePara['isInside']) {
                            window.location.href = ecduri;
                        } else {
                            var ifr = document.createElement('iframe');
                            if (tools.getAndroidVersion() >= 5 && !tools.isWeibo) {
                                if (!tools.isWeibo && !(u.toLowerCase().match(/MicroMessenger/i) == "micromessenger" || u.toLowerCase().match(/QQ/i) == "qq") && !!(u.toLowerCase().match(/samsung sm/i) || (u.toLowerCase().match(/sm-/i)))) {
                                    
                                    ifr.src = ecduri;
                                    ifr.style.display = 'none';
                                    document.body.appendChild(ifr);
                                } else {
                                    window.location.href = ecduri;
                                }

                            } else {

                                ifr.src = ecduri;
                                ifr.style.display = 'none';
                                document.body.appendChild(ifr);

                            }
                        }

                        if (!document.webkitHidden && !defaulePara['isInside']) {
                            tools.download(defaulePara);
                        }

                        return;
                    }

                } else {
                    return false;
                }

            }

        }

    }
};
export default myLib;
