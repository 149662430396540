import Axios from 'axios';

Axios.defaults.baseURL = 'https://api.litup.cc';

export default {
  liveInfo(data) {
    return Axios({
      method: 'post',
      url: '/api/live-share/live-info',
      data
    });
  },
  userList(data) {
    return Axios({
      method: 'post',
      url: '/api/live-share/live-user-list',
      data
    });
  },
  refresh(data) {
    return Axios({
      method: 'post',
      url: '/api/live-share/refresh',
      data
    });
  },
  config(data) {
    return Axios({
      method: 'post',
      url: '/api/live-share/config',
      data
    });
  },
  agoraConfig () {
    return Axios({
      method: 'post',
      url: '/api/live-share/agora-config',
    });
  },
  rtmToken (data) {
    return Axios({
      method: 'post',
      url: '/api/live-share/rtm-token',
      data
    });
  },

}
