import { createApp } from 'vue';
import App from './App.vue';
import mixin from "@/mixin";
import Toaster from '@meforma/vue-toaster';

const app = createApp(App);

// filters
app.config.globalProperties.$filters = {
    numberFilter(num){
        if (num) {
            return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num;
        } else {
            return 0;
        }
    },
}

app.use(Toaster, {
    position: 'top'
})
app.mixin(mixin)
app.mount('#mixins-global')
app.mount('#app')
