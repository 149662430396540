<template>
  <div class="box">
    <div><div class="view-more"></div></div>
    <div class="user-list">
      <div class="item" v-for="(val, index) in list" :key="val.id">
        <div
          class="user-avatar"
          v-if="index < 10"
          @click="open({ type: 'livePage', params: { room_id: room_id } })"
          :style="{
            backgroundImage: `url(${formatAvatar(val.user.avatar)})`,
          }"
        ></div>
      </div>
      <div class="user-count">{{ $filters.numberFilter(count) }}</div>
    </div>
  </div>
</template>

<script>
import Lib from "@/lib";
import Api from "@/api";

export default {
  name: "UserList",
  props: ["list", "count"],
  data() {
    return {
      room_id: "",
      userList: [],
    };
  },
  created() {
    let room_id = Lib.getQuery("id") ? Lib.getQuery("id").split(".")[0] : 0;
    this.room_id = room_id;
    // this.getUserList()
  },
  methods: {
    getUserList() {
      let room_id = this.room_id;
      Api.userList({ room_id }).then((res) => {
        // if(res.data.error !== undefined){

        // }
        this.userList = res.data.data || {};
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 1.22rem;
  background: rgba(219, 249, 29, 1);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  .view-more {
    width: 40px;
    height: 4px;
    background: #ffffff;
    border-radius: 2px;
    margin: 0.1rem auto;
  }
  .user-list {
    width: 95%;
    margin: 0.1rem auto;
    display: flex;
    .item {
      margin-left: 0.05rem;
      .user-avatar {
        width: 0.64rem;
        height: 0.64rem;
        background-size: 100% 100%;
        border-radius: 0.24rem;
        z-index: 1;
      }
    }
    .user-count {
      position: absolute;
      right: 0.2rem;
      height: 0.64rem;
      line-height: 0.64rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 0.24rem;
      font-size: 0.22rem;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #0a0a0a;
      padding: 0 0.2rem;
      z-index: 2;
    }
  }
}
</style>