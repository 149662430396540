<template>
  <div class="share-box">
    <ShareHead />
    <Live />
    <ShareFooter />
  </div>
</template>

<script>
import ShareHead from "./components/ShareHead.vue";
import Live from "./components/Live.vue";
import ShareFooter from "./components/ShareFooter.vue";

export default {
  name: "App",
  components: {
    ShareHead,
    Live,
    ShareFooter,
  },
  data() {
    return {}
  },
  methods: {
  },
};
</script>

<style>
@import "./assets/css/main.css";
</style>

