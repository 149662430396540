<template>
  <div class="head">
    <!-- <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06ead96e0d2773a92aca87c3a70d618cd1e1ce24ba82f5e20431f649e2398aec" class="logo" /> -->
    <div class="logo-img"></div>
    <button class="btn" @click="downLoad('litup://router/')">打开唱造APP</button>
  </div>
</template>

<script>
export default {
  name: "ShareHead",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
	.head {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 1.2rem;
		background: #DBF91D;
		padding: 0.2rem 0.22rem;
		box-sizing: border-box;
		.logo {
			float: left;
			display: block;
			width: 2.6rem;
			height: 0.68rem;
		}
		.logo-img{
			z-index: 40;
			width: 1.58rem;
			height: 0.7rem;
			background: url("../assets/img/logo.png")
				0px 0px no-repeat;
		}
		.btn {
			width: 1.82rem;
			height: 0.6rem;
			line-height: 0.6rem;
			background: #000;
			border-radius: 0.2rem;
			border: 0;
			text-align: center;
			font-size: 0.22rem;
			color: #DBF91D;
			margin-top: 0.1rem;
			font-weight: bold;
		}
}
</style>
