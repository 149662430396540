<template>
    <div class="footer">
        <div class="footer-desc"></div>
        <div class="footer-copyright">
            <div class="about-us">
                <div class="left">
                    <a style="color: #fff;" v-bind:href="data.content.about_us">关于唱造</a>
                    <a style="color: #fff;" v-bind:href="data.content.how_to_use">如何使用唱造</a>
                    <a style="color: #fff;" v-bind:href="data.content.contact_us">联系我们</a>
                    <a style="color: #fff;" v-bind:href="data.content.protocol">隐私协议</a>
                </div>
                <div class="right">
                    <div class="download" @click="downLoad"></div>
                </div>
            </div>
            <div class="copyright">{{ data.copyright }}</div>
        </div>
    </div>
</template>

<script>
// import Lib from "@/lib";
import Api from "@/api";

export default {
    name: 'ShareFooter',
    data() {
        return {
            data: {
                content: {},
                copyright: ''
            }
        };
    },
    created () {
        this.getInfo()
    },
    methods : {
        getInfo () {
            Api.config().then((res) => {
                // if(res.data.error !== undefined){

                // }
                this.data = res.data.data || {};
            });
        }
    }
}

</script>

<style lang="less" scoped>
    .footer{
        background: #000;
        .footer-desc{
            width: 100%;
            height: 0.6rem;
            background: url("../assets/img/desc.png") no-repeat center;
            background-size: 100% 100%;
            // background: rgba(219, 249, 29, 1);
            // color: #000;
            // font-size: 0.28rem;
        }
        .footer-copyright{
            // height: 4rem;
            padding-bottom: 0.62rem;
            margin-left: 0.7rem;
            background: rgba(10, 10, 10, 1);
            .about-us{
                margin-top: 0.64rem;
                display: flex;
                .left{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.26rem;
                    font-family: MaoKenTangYuan-beta, MaoKenTangYuan;
                    a {
                        margin-bottom: 0.18rem;
                    }
                }
                .right{
                    .download{
                        width: 2.42rem;
                        height: 0.64rem;
                        margin: 0 auto;
                        background: url("../assets/img/download.png") no-repeat center;
                        background-size: 100% 100%;
                    }
                }
            }
            .copyright{
                margin-top: 0.38rem;
                font-size: 0.26rem;
                color: rgba(255, 255, 255, 1);
                white-space: break-spaces;
            }
        }
        
    }
</style>