<template>
  <div id="remote-container"></div>
  <template v-if="status">
    <div class="live-box">
      <div class="box-head">
        <div class="anchor">
          <div
            class="anchor-avatar"
            @click="open({ type: 'livePage', params: { room_id: room_id } })"
            :style="{
              backgroundImage: `url(${formatAvatar(data.room.user.avatar)})`,
            }"
          ></div>
          <div
            class="anchor-nickname"
            @click="open({ type: 'livePage', params: { room_id: room_id } })"
          >
            {{ data.room.user && data.room.user.nickname }}
          </div>
          <div class="focus" @click="open({type: 'livePage', params: room_id})" :data-uid="room_id">
            关注
          </div>
        </div>
        <div class="room-info">
          <div class="title">
            <div class="title-slide">{{ data.room && data.room.title }}</div>
          </div>
          <!-- <div class="close-btn" @click="open({type: 'livePage', params: {room_id: room_id}})"></div> -->
          <div :class="volumnClass" @click="play">
            <div class="mute-tips" v-show="isMutePlaying">当前静音播放中，点击取消静音</div>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="onmic-user-list">
          <div class="mc-and-guest">
            <div
              class="mc"
              @click="open({ type: 'livePage', params: { room_id: room_id } })"
            >
              <div
                class="mc-avatar"
                :style="{
                  backgroundImage: `url(${formatAvatar(
                    data.room.user.avatar
                  )})`,
                }"
              ></div>
              <div class="mc-name">
                {{ data.room && data.room.user.nickname }}
              </div>
            </div>
            <div class="guest"></div>
          </div>
          <div class="other-user">
            <div class="seat-no" v-for="(val, index) in seat_list" :key="index">
              <div
                class="have-user"
                @click="
                  open({ type: 'livePage', params: { room_id: room_id } })
                "
                v-if="val.data"
              >
                <div
                  class="user-avatar"
                  :style="{
                    backgroundImage: `url(${formatAvatar(
                      val.data.user.avatar
                    )})`,
                  }"
                ></div>
                <div class="mic-info">
                  <div v-if="val.data.is_mute == 1">
                    <div class="mic-icon"></div>
                  </div>
                  <div class="user-name">{{ val.data.user.nickname }}</div>
                </div>
              </div>
              <div
                class="no-user"
                @click="
                  open({ type: 'livePage', params: { room_id: room_id } })
                "
                v-else
              ></div>
            </div>
          </div>
        </div>
        <div class="box-body-bottom">
          <div class="left">
            <div
              class="chat-list" 
              @touchstart="chatAreaTouchStart" 
              @touchend="chatAreaTouchEnd"
            >
              <div class="room-notice" v-show="data.notice">{{ data.notice }}</div>
              <transition-group name="chatbox" tag="p">
                <div class="chat-item" v-for="item in chat_list" :key="item.id">
                  <div
                    class="user-avatar"
                    :style="{
                      backgroundImage: `url(${formatAvatar(item.user.avatar)})`,
                    }"
                  ></div>
                  <div class="item-other">
                    <div class="user-nickname">{{ item.user.nickname }}</div>
                    <div class="item-content">{{ item.content }}</div>
                  </div>
                </div>
              </transition-group>
            </div>
            <div class="chat-input">
              <div
                class="input-form"
                @click="
                  open({ type: 'livePage', params: { room_id: room_id } })
                "
              >
                说点什么...
              </div>
              <div
                class="mic-btn"
                @click="
                  open({ type: 'livePage', params: { room_id: room_id } })
                "
              ></div>
            </div>
          </div>
          <div class="right">
            <div
              class="apply-btn"
              @click="open({ type: 'livePage', params: { room_id: room_id } })"
            ></div>
            <div
              class="more-btn"
              @click="open({ type: 'livePage', params: { room_id: room_id } })"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <UserList :list="user_list" :count="user_count" />
  </template>
  <template v-else>
    <LiveEnd :how_long="how_long" />
  </template>
</template>

<script>
import Lib from "@/lib";
import Api from "@/api";
import LiveEnd from "./LiveEnd.vue";
import UserList from "./UserList.vue";
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from "agora-rtm-sdk";
// import AgoraRTC from '@/assets/js/AgoraRTC.js';
// import AgoraRTS from '@/assets/js/AgoraRTS.js';

export default {
  name: "Live",
  components: {
    LiveEnd,
    UserList,
  },
  data() {
    return {
      room_id: "",
      status: 1,
      how_long: "",
      data: {
        room: {
          notice: "",
          user: {},
        },
      },
      seat_no: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      seat_list: {},
      chat_list: [],
      user_list: [],
      user_count: 0,
      volumnClass: 'volume volume-icon',
      isMutePlaying: false,
      chatListAreaTouch: false,
      refreshTimer: null,
      agora: {
        client: null,
        rtmClient: null,
        rtmChannel: null,
        options: {
          appId: "",
          channel: "",
          rtcToken: "",
          rtmToken: "",
          uid: "",
        },
        playingStreamList: [],
      },
    };
  },
  created() {
    this.info();
  },
  mounted() {
    if (this.status == 1) {
      // this.refreshTimer = setInterval(() => {
        // this.refreshData();
      // }, 3000);
      this.getAgoraConfig();
    }

    // 监听前后台切换
    this.handleVisibilityChange();
  },
  unmounted() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
    // 登出RTM
    this.loginOutRTM();
    // 离开频道
    this.leaveChannel();
  },
  methods: {
    info() {
      let room_id = Lib.getQuery("id") ? Lib.getQuery("id").split(".")[0] : 0;
      this.room_id = room_id;
      Api.liveInfo({ room_id })
        .then((res) => {
          if (res.data.data == undefined) {
            return false;
          }

          this.data = res.data.data || {};
          this.status = res.data.data.room ? +res.data.data.room.status : 0;
          if (this.status == 0) {
            this.how_long = res.data.data.how_long || "";
            return false;
          }
          this.user_list = res.data.data.user_list;
          this.user_count = res.data.data.count;
          this.agora.options.uid = res.data.data.user_id;
          this.agora.options.rtcToken = res.data.data.rtc_token;

          this.rebuildUserList(res.data.data.onmic_user);

          // 加入频道
          this.joinChannel();
        })
        .catch((err) => {
          this.$toast.error(err.message);
          setTimeout(() => {
            this.downLoad();
          }, 3000);
        });
    },
    refreshData() {
      let room_id = Lib.getQuery("id");
      Api.refresh({ room_id }).then((res) => {
        if (res.data.error !== undefined) {
          clearInterval(this.refreshTimer);
          this.refreshTimer = null;
          this.$toast.error(res.data.error.message);
          setTimeout(() => {
            this.downLoad();
          }, 3000);
          return false;
        }

        this.status = +res.data.data.room.status;
        // 直播结束，销毁定时器
        if (this.status == 0) {
          clearInterval(this.refreshTimer);
          this.refreshTimer = null;
          this.how_long = res.data.data.how_long;
          return false;
        }

        this.rebuildUserList(res.data.data.onmic_user);
        this.user_list = res.data.data.user_list;
        this.user_count = res.data.data.count;
      });
    },
    rebuildUserList(onmic_user) {
      for (var no in this.seat_no) {
        this.seat_list = Object.assign({}, this.seat_list, {
          [this.seat_no[no]]: {
            seat_no: this.seat_no[no],
            data: null,
          },
        });

        for (var user in onmic_user) {
          if (this.seat_no[no] == onmic_user[user].seat_no) {
            var tmpKey = this.seat_no[no];
            var tmpObj = {
              [tmpKey]: {
                seat_no: tmpKey,
                data: onmic_user[user],
              },
            };
            this.seat_list = Object.assign({}, this.seat_list, tmpObj);
            continue;
          }
        }
      }
    },
    createChat() {
      this.chat_list = [
        {
          id: 1,
          content: "flow nice!!!",
          user: {
            nickname: "aka.刘洪",
            avatar:
              "https://static.litup.cc/avatar/1GdoijgBqx2l7oZoQYt9WZKaqKksESpty89CEael.png?imageView2/1/w/100/h/100/q/90",
          },
        },
        {
          id: 2,
          content: "嗓音好甜美",
          user: {
            nickname: "A.",
            avatar:
              "https://static.litup.cc/avatar/54Ma0YpcLa5shElYjNj5G9jaxH1yVitOphS5Bvq4.jpg?imageView2/1/w/100/h/100/q/90",
          },
        },
        {
          id: 3,
          content: "关注了主播",
          user: {
            nickname: "颓废。。。",
            avatar:
              "https://static.litup.cc/avatar/Q9xfURYekMiQihgXbpk6uI7SKOekxkqyQyL8zdBS.jpg?imageView2/1/w/100/h/100/q/90",
          },
        },
        {
          id: 4,
          content: "进入了直播间",
          user: {
            nickname: "warm",
            avatar:
              "https://static.litup.cc/avatar/jn3auXbM2oSXQ7bPMxNp79eFl41wtfMP6nmON692.jpg?imageView2/1/w/100/h/100/q/90",
          },
        },
        {
          id: 5,
          content:
            "再来一首，再来一首🌹🌹🌹🌹🌹🌹再来一首，再来一首🌹🌹🌹🌹🌹🌹再来一首，再来一首🌹🌹🌹🌹🌹🌹",
          user: {
            nickname: "Beatii",
            avatar:
              "https://static.litup.cc/avatar/54Ma0YpcLa5shElYjNj5G9jaxH1yVitOphS5Bvq4.jpg?imageView2/1/w/100/h/100/q/90",
          },
        },
      ];
    },
    deepLink() {
      let room_id = this.room_id;
      Lib.deepLink({ type: "livePage", room_id});
    },
    getAgoraConfig() {
      Api.agoraConfig().then((res) => {
        this.agora.options.channel = String(this.room_id);
        this.agora.options.appId = res.data.data.app_id;
      });
    },
    joinChannel() {
      let that = this;
      let handleError = (err) => {
        console.log("Agora Error: ", err);
      };

      let remoteContainer = document.getElementById("remote-container");

      let addVideoStream = (elementId) => {
        let streamDiv = document.createElement("div");
        streamDiv.id = elementId;
        remoteContainer.appendChild(streamDiv);
      };

      let removeVideoStream = (elementId) => {
        let remoteDiv = document.getElementById("elementId");
        if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
      };

      this.agora.client = AgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
      });

      // H5实时直播优化方案
      // AgoraRTS.init(AgoraRTC, {
      //   wasmDecoderPath: "@/assets/js/AgoraRTS.wasm",
      //   asmDecoderPath: "@/assets/js/AgoraRTS.asm",
      // }).catch( e => {
      //   console.log("加载解码器失败", e);
      // });
      // console.log("加载解码器成功");

      // AgoraRTS.proxy(this.agora.client);
      // this.agora.client.enableAudioVolumeIndicator();

      // if(!AgoraRTS.checkSystemRequirements()){
      //   this.$toast.error("您的浏览器不支持 RTS");
      // }

      this.agora.client.init(this.agora.options.appId);
      this.agora.client.setClientRole("audience");

      var remoteStreams = {};

      console.log("加入频道");
      this.agora.client.join(
        this.agora.options.rtcToken,
        this.agora.options.channel,
        Number(this.agora.options.uid),
        (uid) => {
          console.log("加入频道成功:"+ uid);
          console.log("开始登录RTM")
          that.loginRTM();
        },
        handleError
      );

      this.agora.client.on("stream-type-changed", function (e) {
        console.log("stream-type-changed", e);
      });

      // 订阅远端流
      this.agora.client.on("stream-added", (evt) => {
        console.log("监听到流");
        this.agora.client.subscribe(
          evt.stream,
          // { video: false, audio: true },   // Safari不支持订阅单个轨道
          handleError
        );
        remoteStreams[evt.stream.getId()] = evt.stream;
        console.log("订阅成功");
      });

      this.agora.client.on("stream-subscribed", (evt) => {
        console.log("主播直播中。。。");
        let stream = evt.stream;
        let streamId = String(stream.getId());
        addVideoStream(streamId);
        // 静音播放
        stream.play(streamId, function(err){
          console.log('自动播放失败', err);
          // 浏览器自动播放失败
          if(err && err.status !== "aborted"){
            that.volumnClass = "volume volume-close-icon";
            that.isMutePlaying = true;
            stream.stop();
            stream.play(streamId, {muted: true});
            // setTimeout(() => {
              // 隐藏提示
            // }, 10000);
          }
        });
        this.agora.playingStreamList[stream.getId()] = stream;
      });

      // 远端流关闭
      this.agora.client.on("stream-removed", (evt) => {
        let stream = evt.stream;
        let streamId = String(stream.getId());
        stream.close();
        delete remoteStreams[streamId];
        removeVideoStream(streamId);
      });

      this.agora.client.on("peer-leave", (evt) => {
        console.log("直播结束");
        let stream = evt.stream;
        let streamId = String(stream.getId());
        stream.close();
        delete remoteStreams[streamId];
        removeVideoStream(streamId);
      });
    },
    getRtmToken () {
      return Api.rtmToken({ user_id: this.agora.options.uid }).then((res) => {
        this.agora.options.rtmToken = res.data.data.token;
      });
    },
    async loginRTM() {
      await this.getRtmToken();
      let that = this;
      let client = AgoraRTM.createInstance(this.agora.options.appId);

      await client
      .login({
        token: that.agora.options.rtmToken,
        uid: that.agora.options.uid,
      })
      .then(() => {
        console.log("登录成功");
      })
      .catch((e) => {
        console.log("登录失败", e);
        return false;
      });

      this.agora.rtmClient = client;

      this.agora.rtmChannel = this.agora.rtmClient.createChannel(
        this.agora.options.channel
      );

      await this.agora.rtmChannel.join().then(() => {
        console.log("RTM加入频道成功");
      });
      
      // 监听消息
      this.agora.rtmClient.on("MessageFromPeer", function (message, peerId) {
        console.log("收到C2C消息");
        console.log("message", message);
        console.log("peerId", peerId);
      });

      this.agora.rtmChannel.on("ChannelMessage", function (message, memberId) {
        console.log("收到Group消息");
        console.log("memberId", memberId);
        let msgData = JSON.parse(message.text);
        console.log(msgData);
        switch (msgData.type) {
          // 进入直播间
          case "enterlive":
            that.chat_list.push({
              id: that.chat_list.length + 1,
              content: msgData.msg,
              user: {
                nickname: msgData.data.user_info.nickname,
                avatar: msgData.data.user_info.avatar,
              },
            });
            that.chatListAreaScroll();
            that.user_list = msgData.data.user_list;
            that.user_count = msgData.data.count;
            break;
          // 用户上麦
          case "newMicUser":
            that.seat_list[msgData.data.seat_no] = {
              seat_no: msgData.data.seat_no,
              data: {
                is_mute: msgData.data.is_mute,
                user: msgData.data.userInfo,
              },
            };
            break;
          // 用户下麦
          case "offMicUser":
            that.seat_list[msgData.data.seat_no] = {
              seat_no: msgData.data.seat_no,
              data: null,
            };
            break;
          // 离开直播间
          case "leavelive":
            that.user_list = msgData.data.user_list;
            that.user_count = msgData.data.count;
            break;
          // 直播结束
          case "liveEnd":
            // 登出RTM
            that.loginOutRTM();
            // 离开频道
            that.leaveChannel();
            that.status = 0;
            that.how_long = msgData.data.how_long;
            if (that.refreshTimer) {
              clearInterval(that.refreshTimer);
              that.refreshTimer = null;
            }
            break;
          // 普通消息
          case "inviteOnChat":
            that.chat_list.push({
              id: that.chat_list.length + 1,
              content: msgData.data.messsge,
              user: {
                nickname: msgData.data.nick,
                avatar: msgData.data.avatar,
              },
            });
            that.chatListAreaScroll();
            break;
          default:
            break;
        }
      });

      // 连接状态变化
      this.agora.rtmClient.on(
        "ConnectionStateChanged",
        function (state, reason) {
          console.log("连接状态变化");
          console.log("State change:" + state + " Reason: " + reason);
        }
      );
    },
    loginOutRTM() {
      console.log('退出登录')
      async () => {
        console.log('here')
        if (this.agora.rtmChannel) await this.agora.rtmChannel.leave();
        if (this.agora.rtmClient) await this.agora.rtmClient.logout();
      }
    },
    leaveChannel() {
      if (this.agora.client) this.agora.client.leave();
    },
    play() {
      this.isMutePlaying = !this.isMutePlaying;
      // 静音播放
      if(this.isMutePlaying){
        this.volumnClass = "volume volume-close-icon";
        for (let i in this.agora.playingStreamList) {
          let stream = this.agora.playingStreamList[i];
          console.log(stream.getId());
          stream.stop();
          stream.play(String(stream.getId()), { muted: true });
          console.log(stream.getAudioLevel());
        }
      }
      // 取消静音播放
      else{
        this.volumnClass = "volume volume-icon";
        for (let i in this.agora.playingStreamList) {
          let stream = this.agora.playingStreamList[i];
          console.log(stream.getId());
          stream.stop();
          stream.play(String(stream.getId()), { muted: false });
          console.log(stream.getAudioLevel());
        }
      }
    },
    chatListAreaScroll () {
      if(!this.chatListAreaTouch){
        setTimeout( () => {
          let el = document.getElementsByClassName("chat-list")[0];
          if(el.scrollHeight > el.offsetHeight + el.scrollTop){
            el.scrollTop = el.scrollHeight - el.offsetHeight;
          }
        }, 500);
      }
    },
    chatAreaTouchStart () {
      this.chatListAreaTouch = true;
    },
    chatAreaTouchEnd () {
      this.chatListAreaTouch = false;
    },
    handleVisibilityChange () {
      let hidden, visibilityChange, that = this;
      if(typeof document.hidden !== undefined){
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      }
      else if(typeof document.msHidden !== undefined){
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      }
      else if(typeof document.webkitHidden !== undefined){
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      document.addEventListener(visibilityChange, (e) => {
        if(document[hidden]){
          for (let i in that.agora.playingStreamList) {
            let stream = that.agora.playingStreamList[i];
            stream.stop();
          }
        }
        else{
          for (let i in that.agora.playingStreamList) {
            let stream = that.agora.playingStreamList[i];
            stream.play(String(stream.getId()), { muted: false });
          }
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.live-box {
  display: block;
  width: 100%;
  background: #000;
  position: relative;
}
.box-head {
  width: 100%;
  padding: 0.14rem 0.32rem;
  margin-top: 0.14rem;
  box-sizing: border-box;
  .anchor {
    display: flex;
    width: 4rem;
    height: 0.6rem;
    padding: 0 0.05rem;
    background: rgba(25, 26, 28, 1);
    border-radius: 0.4rem;
    float: left;
    position: relative;
    .anchor-avatar {
      float: left;
      width: 0.6rem;
      height: 0.6rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #efefef;
      position: relative;
      border-radius: 0.2rem;
      margin-right: 0.08rem;
      margin-left: 0.04rem;
    }
    .anchor-nickname {
      font-size: 0.26rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 2rem;
      height: 0.6rem;
      line-height: 0.6rem;
      font-weight: bold;
      color: #efefef;
    }
    .focus {
      background-color: rgba(219, 249, 29, 1);
      font-size: 0.26rem;
      width: 1.2rem;
      height: 0.6rem;
      line-height: 0.6rem;
      color: #000;
      border-radius: 0.4rem;
      text-align: center;
      position: absolute;
      right: 0.15rem;
    }
  }
  .room-info {
    width: 1.6rem;
    height: 0.44rem;
    float: right;
    position: relative;
    display: flex;
    .title {
      font-size: 0.26rem;
      white-space: nowrap;
      // text-overflow: ellipsis;
      overflow: hidden;
      width: 1rem;
      height: 0.6rem;
      line-height: 0.6rem;
      font-weight: bold;
      color: #efefef;
      .title-slide{
        width: fit-content;
        width: -moz-fit-content;
        position: relative;
        animation: left-slide ease 5s infinite normal;
        -moz-animation: left-slide ease 5s infinite normal;
        -webkit-animation: left-slide linear 5s infinite normal;
        -o-animation: left-slide ease 5s infinite normal;
      }
      @keyframes left-slide{
        0%{
          left: 40%;
        }
        25%{
          left: -30%;
        }
        50%{
          left: -100%;
        }
        75%{
          left: -170%;
        }
        100%{
          left: -240%;
        }
      }
      @-moz-keyframes left-slide{

      }
      @-webkit-keyframes left-slide{
        0%{
          left: 5%;
        }
        25%{
          left: -25%;
        }
        50%{
          left: -50%;
        }
        75%{
          left: -75%;
        }
        100%{
          left: -100%;
        }
      }
      @-o-keyframes left-slide{
        
      }
    }
    .close-btn {
      width: 0.28rem;
      height: 0.28rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../assets/img/close.png");
      background-image: -webkit-image-set(
        url("../assets/img/close.png") 1x,
        url("../assets/img/close@2x.png") 2x
      );
      background-image: -moz-image-set(
        url("../assets/img/close.png") 1x,
        url("../assets/img/close@2x.png") 2x
      );
      background-image: -o-image-set(
        url("../assets/img/close.png") 1x,
        url("../assets/img/close@2x.png") 2x
      );
      background-image: -ms-image-set(
        url("../assets/img/close.png") 1x,
        url("../assets/img/close@2x.png") 2x
      );
      position: relative;
      border-radius: 0.2rem;
      margin-right: 0.08rem;
      margin-top: 0.18rem;
      margin-left: 0.04rem;
    }
    .volume{
      width: 0.6rem;
      height: 0.6rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      border-radius: 0.2rem;
      margin-left: 0.04rem;
      cursor: pointer;
    }
    .volume.volume-icon{
      background-image: url("../assets/img/volume.png");
    }
    .volume.volume-close-icon{
      background-image: url("../assets/img/close_volume.png");
    }
    .mute-tips{
      text-align: center;
      font-size: 0.26rem;
      position: absolute;
      width: 3.8rem;
      height: 0.3rem;
      line-height: 0.3rem;
      right: 0;
      top: 0.7rem;
      background: rgba(49, 49, 49, 0.8);
      color: #50e3c2;
      padding: 0.2rem 0.15rem;
      border-radius: 0.3rem;
      z-index: 99;
    }
    .mute-tips::after{
      content: "";
      position: absolute;
      border-style: solid;
      border-color: transparent transparent rgba(49, 49, 49, 0.8) transparent;
      border-width: 0.15rem;
      top: -0.3rem;
      right: 0.25rem;
    }
  }
}
.box-body {
  width: 100%;
  margin-top: 1.14rem;
  .onmic-user-list {
    width: 100%;
    min-height: 5rem;
  }
  .mc-and-guest {
    width: 100%;
  }
  .mc {
    width: 2rem;
    height: 1.8rem;
    margin: 0 auto;
    background: url("../assets/img/MC.png") no-repeat center;
    background-size: 100% 100%;
    z-index: 1;
    position: relative;
    .mc-avatar {
      width: 0.8rem;
      height: 0.8rem;
      background-size: 100% 100%;
      position: absolute;
      top: 0.4rem;
      left: 0.6rem;
      border-radius: 0.25rem;
    }
    .mc-name {
      position: absolute;
      bottom: 0rem;
      font-size: 0.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      color: #efefef;
      width: 100%;
      text-align: center;
    }
  }
  .other-user {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0.3rem;
    margin-top: 0.2rem;
    .seat-no {
      width: 0.8rem;
      height: 1.2rem;
      margin-left: 0.3rem;
      margin-top: 0.2rem;
      .have-user {
        .mic-info {
          display: flex;
          margin-top: 0.05rem;
        }
        .user-avatar {
          width: 0.8rem;
          height: 0.8rem;
          background-size: 100% 100%;
          border-radius: 0.25rem;
          border: 0.01rem solid gray;
        }
        .user-name {
          font-size: 0.18rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: bold;
          color: #808080;
          width: 100%;
          text-align: center;
        }
        .mic-icon {
          width: 0.24rem;
          height: 0.24rem;
          background: url("../assets/img/mute.png") no-repeat center;
          background-size: 100% 100%;
        }
      }
      .no-user {
        width: 0.8rem;
        height: 1.2rem;
        background: url("../assets/img/onmic@2x.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
}
.box-body-bottom {
  width: 95%;
  margin: 0.8rem 0 0.2rem 0.32rem;
  display: flex;
  .left {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .chat-list {
      height: 4rem;
      overflow-y: scroll;
      scroll-behavior: smooth;
      .room-notice {
        font-size: 0.26rem;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #50e3c2;
        line-height: 0.38rem;
        background: rgba(49, 49, 49, 0.5);
        border-radius: 0.32rem;
        padding: 0.2rem 0.16rem;
      }
      .chat-item {
        transition: all 0.3s ease;
        background: rgba(49, 49, 49, 0.5);
        border-radius: 0.32rem;
        display: flex;
        margin-top: 0.2rem;
        padding-left: 0.16rem;
        padding-right: 0.25rem;
        padding-top: 0.18rem;
        padding-bottom: 0.16rem;
        width: fit-content;
        .user-avatar {
          width: 0.48rem;
          min-width: 0.48rem;
          height: 0.48rem;
          background-size: 100% 100%;
          border-radius: 0.15rem;
        }
        .item-other {
          display: flex;
          flex-direction: column;
          margin-left: 0.15rem;
          width: 80%;
          .user-nickname {
            font-size: 0.22rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            color: #fff;
            width: 100%;
          }
          .item-content {
            font-size: 0.26rem;
            color: #fff;
            width: 100%;
          }
        }
      }
      .chatbox-enter-from,
      .chatbox-leave-to {
        opacity: 0;
        transform: translateY(30px);
      }
      .chatbox-leave-active {
        position: absolute;
      }
    }
    .chat-list::-webkit-scrollbar {
      width: 0 !important;
    }
    .chat-input {
      display: flex;
      justify-content: space-around;
      margin-top: 0.2rem;
      .input-form {
        background: rgba(49, 49, 49, 0.5);
        border-radius: 0.2rem;
        padding-left: 0.16rem;
        padding-right: 0.25rem;
        padding-top: 0.18rem;
        padding-bottom: 0.16rem;
        font-size: 0.26rem;
        color: #fff;
        height: 0.46rem;
        flex-grow: 1;
      }
      .mic-btn {
        width: 0.8rem;
        height: 0.8rem;
        background: url("../assets/img/mic.png") no-repeat center;
        background-size: 100% 100%;
        // border: 0.01rem dashed lightgray;
        margin-left: 0.2rem;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0.2rem;
    .apply-btn {
      width: 1rem;
      height: 1rem;
      background: url("../assets/img/apply.png") no-repeat center;
      background-size: 100% 100%;
      //   border: 0.01rem dashed lightgray;
    }
    .more-btn {
      width: 1rem;
      height: 1rem;
      background: url("../assets/img/more.png") no-repeat center;
      background-size: 100% 100%;
      //   border: 0.01rem dashed lightgray;
      margin-top: 0.2rem;
    }
  }
}
</style>