import Lib from "@/lib";
export default {
    methods: {
        downLoad(path) {
            if (typeof path === 'string') {
                window.location.href = path
            } else {
                window.location.href = 'https://m.litup.cc/'

            }
        },
        open(option) {
            Lib.deepLink().open(option)
        },
        formatAvatar(img) {
          if (img === undefined || img == null) {
            return 'https://static.litup.cc/avatar/0F5sO2vR4hGU38XtPu4YusBcszT2jA5PwviDDr7V.png';
          }
          return img;
        }
    }
}