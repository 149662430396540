<template>
    <div class="live-end">
        <div class="desc">直播已结束</div>
        <div class="long">{{how_long}}</div>
    </div>
</template>

<script>
export default {
    name: 'LiveEnd',
    props: [
        'how_long',
    ],
}
</script>

<style lang="less" scoped>
    .live-end{
        width: 100%;
        height: 7.5rem;
        text-align: center;
        .desc{
            margin-top: 2.24rem;
            font-size: 0.4rem;
            color: rgba(255, 255, 255, 1);
        }
        .long{
            font-size: 0.26rem;
            color: rgba(128, 128, 128, 1);
            margin-top: 0.16rem;
        }
    }
</style>>